import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Gilroy",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#000000",
    },
    background: {
      default: "#f6f6f6",
    },
    text: {
      primary: "#000000",
    },
  },
  shadows: ["none"],
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 50,
      },
    },
  },
})

export default theme
