import React, { ReactNode } from "react"
import { ThemeProvider as StyledThemeProvider } from "styled-components"

import ThemeProvider from "@material-ui/styles/ThemeProvider"

import Layout from "./components/layout"
import theme from "./theme"

interface WrapRootElementParam {
  element?: ReactNode
}

const wrapRootElement: React.FC<Props> = ({
  element,
}: WrapRootElementParam) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>{element}</StyledThemeProvider>
    </ThemeProvider>
  )
}

interface WrapPageElementParam {
  element?: ReactNode
  props?: Record<string, unknown>
}

const wrapPageElement: React.FC<Props> = ({
  element,
  props,
}: WrapPageElementParam) => {
  return <Layout {...props}>{element}</Layout>
}

export { wrapRootElement, wrapPageElement }
