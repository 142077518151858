import React from "react"
import { Helmet } from "react-helmet"

import CssBaseline from "@material-ui/core/CssBaseline"
import { makeStyles } from "@material-ui/core/styles"

import Footer from "./footer"
import NavBar from "./nav-bar"

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
  },
}))

interface Props {
  children?: any
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  const classes = useStyles()

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
        <meta name="google-site-verification" content="" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0, shrink-to-fit=no"
        />
      </Helmet>
      <CssBaseline />
      <main className={classes.root}>
        <NavBar />
        {children}
        <Footer />
      </main>
    </div>
  )
}

export default Layout
