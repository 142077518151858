/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import React from "react"

import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import EventIcon from "@material-ui/icons/Event"
import PhoneIcon from "@material-ui/icons/Phone"

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}))

const NavBar: React.FC<Record<string, unknown>> = () => {
  const classes = useStyles()
  const wideScreen = useMediaQuery((theme: Theme): string =>
    theme.breakpoints.up("sm"),
  )

  return (
    <AppBar position="static" color="transparent">
      <Toolbar variant="regular">
        <Typography variant="h6" className={classes.title} color="inherit">
          {""}
        </Typography>
        {wideScreen && (
          <>
            <Button
              color="inherit"
              variant="text"
              className={classes.button}
              href="/"
            >
              About
            </Button>
            <Button
              color="inherit"
              variant="text"
              className={classes.button}
              href="mailto:drepavlova@health-worx.co.za"
            >
              Contact
            </Button>
          </>
        )}
        <Button
          color="inherit"
          variant="text"
          startIcon={<PhoneIcon />}
          href="tel:+27120103650"
          className={classes.button}
        >
          012 010 3650
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<EventIcon />}
          className={classes.button}
          href="https://www.mygc.co.za/external-booking?practice=255aa39924&diary=7"
          target="_blank"
        >
          Make appointment
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
