import React from "react"

import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}))

const Footer: React.FC<Record<string, unknown>> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Toolbar variant="regular">
        <Typography variant="caption">
          &copy; 2020 Dr. Elena Pavlova. All Rights Reserved.
        </Typography>
      </Toolbar>
    </div>
  )
}

export default Footer
